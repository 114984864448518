<template>
  <LSection>
    <template #body>
      <div class="flex justify-center">
        <div v-if="!hasRegistered">
          <form @submit.prevent="onSubmit" ref="form" class="form">
            <h1 class="heading1">
              Registration
              <hr class="gradientLine"/>
            </h1>
            <div class="grid grid-cols-2 justify-center align-center">
              <div class="flex flex-col">
                <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                  <label for="name">Organisation Name*</label>
                  <TextInput
                      autofocus
                      :tabIndex="1"
                      id="name"
                      name="name"
                      placeholder="Name"
                      @change="value => updateOrganisation('name', value, true)"
                  ></TextInput>
                  <LFieldError :message="inputError.name" class="w-full">
                  </LFieldError>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                  <label for="description">Description*</label>
                  <TextArea
                      type="text"
                      :tabIndex="2"
                      id="description"
                      name="description"
                      placeholder="Organisation Description"
                      @change="value => updateOrganisation('description', value, true)"
                  ></TextArea>
                  <LFieldError :message="inputError.description" class="w-full">
                  </LFieldError>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                  <label for="contact">Contact Person*</label>
                  <TextInput
                      :tabIndex="3"
                      id="contact"
                      name="contact"
                      placeholder="Contact Person Full Name"
                      @change="value => updateOrganisation('contact', value, true)"
                  ></TextInput>
                  <LFieldError :message="inputError.contact" class="w-full">
                  </LFieldError>
                </div>
              </div>
              <div class="flex flex-row justify-center items-start">
                <div class="flex flex-col gap-6">
                  <img
                      class="rounded-full object-contain w-44 h-44"
                      :src="
                                              organisation.imageUrl || 'https://via.placeholder.com/150'
                                            "
                  />
                  <label>
                    <ImageUrlInput
                        :isUniqueImage="true"
                        label="Upload Logo"
                        @change="onImageUrlChange"
                    ></ImageUrlInput>
                  </label>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap">
              <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                <label for="email">Contact Email*</label>
                <TextInput
                    :tabIndex="4"
                    id="email"
                    name="email"
                    placeholder="Contact Email"
                    @change="value => updateOrganisation('email', value, true)"
                ></TextInput>
                <LFieldError :message="inputError.email" class="w-full">
                </LFieldError>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                <label for="link">Link to organisation website*</label>
                <TextInput
                    :tabIndex="5"
                    id="link"
                    name="link"
                    placeholder="Link"
                    @change="value => updateOrganisation('link', value, true)"
                ></TextInput>
                <LFieldError :message="inputError.link" class="w-full">
                </LFieldError>
              </div>

              <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                <label for="country">Country*</label>
                <DropdownSingle
                    :title="organisation.country || 'Select Country'"
                    :items="countries"
                    :tabIndex="6"
                    id="country"
                    name="country"
                    placeholder="Select Country"
                    @select="value => updateOrganisation('country', value, true)"
                />
                <LFieldError :message="inputError.country" class="w-full">
                </LFieldError>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                <label for="category">Category*</label>
                <DropdownSingle
                    :title="organisation.category || 'Select Category'"
                    :items="organisationCategories"
                    :tabIndex="7"
                    id="category"
                    name="category"
                    placeholder="category"
                    @select="value => updateOrganisation('category', value,   true)"
                ></DropdownSingle>
                <LFieldError :message="inputError.category" class="w-full">
                </LFieldError>
              </div>
              <div v-if="showOther" class="w-full md:w-1/2 lg:w-1/3 mb-6">
                <label for="otherDescription">
                  Please describe your organisation category*
                </label>
                <TextInput
                    :tabIndex="8"
                    id="otherDescription"
                    name="otherDescription"
                    placeholder="Other Description"
                    @change="value => updateOrganisation('otherDescription', value)"
                ></TextInput>
                <LFieldError :message="inputError.otherDescription" class="w-full"></LFieldError>
              </div>

              <div
                  v-if="showEmployeeCount"
                  class="w-full md:w-1/2 lg:w-1/3 mb-6"
              >
                <label for="employeeCount">Company size*</label>
                <DropdownSingle
                    :title="organisation.employeeCount || 'Select Size'"
                    :items="companySizes"
                    placeholder="Employee Count"
                    @select="value => updateOrganisation('employeeCount', value)"
                ></DropdownSingle>
              </div>
              <div class="w-full mb-6">
                <label for="industryCategory">Industry of your Company*</label>
                <DropdownSingle
                    :title="organisation.industryCategory || 'Select Industry'"
                    :items="categories"
                    placeholder="Select Industry"
                    @select="onIndustryCategoryChange"
                ></DropdownSingle>
              </div>

              <div class="w-full mb-6" v-if="showSubCategory">
                <label for="subCategory">Industry Sub-Category*</label>
                <DropdownSingle
                    :disable="subCategoriesAreDisabled"
                    :title="organisation.subCategory || 'Select Sub-Category'"
                    :items="subCategories[organisation.industryCategory]"
                    placeholder="Select Sub-Category"
                    @select="value => updateOrganisation('subCategory', value)"
                ></DropdownSingle>
              </div>

              <div class="heading3">Optional</div>
              <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                <label for="linkedIn">LinkedIn</label>
                <TextInput
                    :tabIndex="11"
                    id="linkedIn"
                    name="linkedIn"
                    placeholder="Link for LinkedIn"
                    @change="value => updateOrganisation('linkedIn', value)"
                ></TextInput>
              </div>

              <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                <label for="twitter">Twitter</label>
                <TextInput
                    :tabIndex="12"
                    id="twitter"
                    name="twitter"
                    placeholder="Link for Twitter"
                    @change="value => updateOrganisation('twitter', value)"
                ></TextInput>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                <label for="twitter">Facebook</label>
                <TextInput
                    :tabIndex="13"
                    id="facebook"
                    name="facebook"
                    placeholder="Link for Facebook"
                    @change="value => updateOrganisation('facebook', value)"
                ></TextInput>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                <label for="twitter">Instagram</label>
                <TextInput
                    :tabIndex="14"
                    id="instagram"
                    name="instagram"
                    placeholder="Link for Instagram"
                    @change="value => updateOrganisation('instagram', value)"
                ></TextInput>
              </div>
            </div>
            <div class="items-center justify-end gap-4 flex flex-row">
              <Button
                  class="button secondary"
                  @click="$router.go(-1)"
                  type="submit"
              >
                <template #right>Back</template>
              </Button>
              <Button class="button primary" @click="onSubmit"
                      :is-primary="isValid"
                      :is-disabled="!isValid" type="submit">
                <template #right>Register</template>
              </Button>
            </div>
            <LFieldError :message="inputError.submit" class="w-full text-end"></LFieldError>
          </form>

        </div>
        <div v-else>
          <SuccessMessage
              title="Succes!"
              message="Your registration was recieved, check your email to verify your email!"
          >
          </SuccessMessage>
          <div class="items-center justify-center flex flex-col">
            <button class="button primary" @click="$router.push('/')">
              Return to home
            </button>
          </div>
        </div>
      </div>
    </template>
  </LSection>
</template>

<script>
import LSection from "@/components/layout/LSection.vue";
import TextArea from "@/components/forms/TextArea.vue";
import TextInput from "@/components/forms/TextInput.vue";
import {computed, ref} from "vue";
import Button from "@/components/forms/Button.vue";
import {reactive} from "vue";
import SuccessMessage from "@/components/forms/SuccessMessage.vue";
import DropdownSingle from "@/components/forms/DropdownSingle.vue";
import {useDocuments} from "@/composables/useDocuments";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import registerUserAccount from "@/components/authentication/RegisterUserAccount.vue";
// import UploadImage from "@/components/image/UploadImage.vue";
import ImageUrlInput from "@/components/forms/ImageUrlInput.vue";
import {countries} from "@/components/Organisation/lib/countries";
import LFieldError from "@/components/layout/LFieldError.vue";

const companySizes = [
  "1-10",
  "11-50",
  "51-100",
  "101-500",
  "501-1000",
  "1001-5000",
  "5001-10000",
  "10001+",
];
const organisationCategories = [
  "Public institution",
  "School",
  "University",
  "Company",
  "Other",
];
const categories = [
  "Agriculture",
  "Arts",
  "Construction",
  "Consumer Goods",
  "Corporate Services",
  "Design",
  "Education",
  "Energy & Mining",
  "Entertainment",
  "Finance",
  "Hardware & Networking",
  "Health Care",
  "Legal",
  "Manufacturing",
  "Media & Communications",
  "Nonprofit",
  "Public Administration",
  "Public Safety",
  "Real Estate",
  "Recreation & Travel",
  "Retail",
  "Software & IT Services",
  "Transportation & Logistics",
  "Wellness & Fitness",
];
const subCategories = {
  Education: [
    "Education Management",
    "E-Learning",
    "Higher Education",
    "Primary/Secondary Education",
    "Research",
  ],
  Construction: ["Building Materials", "Civil Engineering", "Construction"],
  Design: ["Architecture & Planning", "Design", "Graphic Design"],
  "Corporate Services": [
    "Accounting",
    "Business Supplies & Equipment",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Human Resources",
    "Information Services",
    "Management Consulting",
    "Outsourcing/Offshoring",
    "Professional Training & Coaching",
    "Security & Investigations",
    "Staffing & Recruiting",
  ],
  Retail: ["Retail", "Supermarkets", "Wholesale"],
  "Energy & Mining": ["Mining & Metals", "Oil & Energy", "Utilities"],
  Manufacturing: [
    "Automotive",
    "Aviation & Aerospace",
    "Chemicals",
    "Defense & Space",
    "Electrical & Electronic Manufacturing",
    "Food Production",
    "Glass, Ceramics & Concrete",
    "Industrial Automation",
    "Machinery",
    "Mechanical or Industrial Engineering",
    "Packaging & Containers",
    "Paper & Forest Products",
    "Plastics",
    "Railroad Manufacture",
    "Renewables & Environment",
    "Shipbuilding",
    "Textiles",
  ],
  Finance: [
    "Banking",
    "Capital Markets",
    "Financial Services",
    "Insurance",
    "Investment Banking",
    "Investment Management",
    "Venture Capital & Private Equity",
  ],
  "Recreation & Travel": [
    "Airlines/Aviation",
    "Gambling & Casinos",
    "Hospitality",
    "Leisure, Travel & Tourism",
    "Restaurants",
    "Recreational Facilities & Services",
    "Sports",
  ],
  Arts: ["Arts & Crafts", "Fine Art", "Performing Arts", "Photography"],
  "Health Care": [
    "Biotechnology",
    "Hospital & Health Care",
    "Medical Device",
    "Medical Practice",
    "Mental Health Care",
    "Pharmaceuticals",
    "Veterinary",
  ],
  "Hardware & Networking": [
    "Computer Hardware",
    "Computer Networking",
    "Nanotechnologie",
    "Semiconductors",
    "Telecommunications",
    "Wireless",
  ],
  "Real Estate": ["Commercial Real Estate", "Real Estate"],
  Legal: ["Alternative Dispute Resolution", "Law Practice", "Legal Services"],
  "Consumer Goods": [
    "Apparel & Fashion",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Food & Beverages",
    "Furniture",
    "Luxury Goods & Jewelry",
    "Sporting Goods",
    "Tobacco",
    "Wine and Spirits",
  ],
  Agriculture: ["Dairy", "Farming", "Fishery", "Ranching"],
  "Media & Communications": [
    "Market Research",
    "Marketing & Advertising",
    "Newspapers",
    "Online Media",
    "Printing",
    "Public Relations & Communications",
    "Publishing",
    "Translation & Localization",
    "Writing & Editing",
  ],
  Nonprofit: [
    "Civic & Social Organization",
    "Fundraising",
    "Individual & Family Services",
    "International Trade & Development",
    "Libraries",
    "Museums & Institutions",
    "Non-Profit Organization Management",
    "Philanthropy",
    "Program Development",
    "Religious Institutions",
    "Think Tanks",
  ],
  "Software & IT Services": [
    "Computer & Network Security",
    "Computer Software",
    "Information Technology & Services",
    "Internet",
  ],
  "Transportation & Logistics": [
    "Import & Export",
    "Logistics & Supply Chain",
    "Maritime",
    "Package/Freight Delivery",
    "Transportation/Trucking/Railroad",
    "Warehousing",
  ],
  Entertainment: [
    "Animation",
    "Broadcast Media",
    "Computer Games",
    "Entertainment",
    "Media Production",
    "Mobile Games",
    "Motion Pictures & Film",
    "Music",
  ],
  "Wellness & Fitness": ["Alternative Medicine", "Health, Wellness & Fitness"],
  "Public Safety": ["Law Enforcement", "Military", "Public Safety"],
  "Public Administration": [
    "Government Administration",
    "Government Relations",
    "International Affairs",
    "Judiciary",
    "Legislative Office",
    "Political Organization",
    "Public Policy",
  ],
};

export default {
  name: "RegisterNewOrganisation",
  computed: {
    registerUserAccount() {
      return registerUserAccount;
    },
  },
  components: {
    LFieldError,
    // UploadImage,
    Button,
    DropdownSingle,
    ImageUrlInput,
    LSection,
    TextArea,
    TextInput,
    SuccessMessage,
  },
  setup() {
    const subCategoriesAreDisabled = ref(true);
    const showSubCategory = ref(false);
    const showEmployeeCount = ref(false);
    const showOther = ref(false);
    const hasRegistered = ref(false);
    const form = ref(null);
    const inputError = reactive({});
    const formError = ref(null);

    const today = new Date().toISOString();
    const {createDocument} = useDocuments("Organisation");
    const store = useStore();

    const isValid = computed(
        () =>
            organisation.name &&
            organisation.description &&
            organisation.contact &&
            organisation.email &&
            organisation.link &&
            organisation.category &&
            organisation.country &&
            organisation.industryCategory &&
            Object.values(inputError).every((msg) => msg === null)
    );


    const organisation = reactive({
      dataType: "Organisation",
      name: null,
      description: null,
      contact: null,
      email: null,
      link: null,
      category: null,
      subCategory: null,
      employeeCount: null,
      imageUrl: null,
      otherDescription: null,
      country: null,
      industryCategory: null,
      facebook: null,
      twitter: null,
      instagram: null,
      linkedIn: null,
    });

    const router = useRouter();


    const onSubmit = () => {
      if (!isValid.value) {
        formError.value = "Please fill in all required fields";
        return;
      }
      inputError.submit = null;
      store.state.UI.message = "";
      organisation.dateAdded = today;
      console.log(isValid.value);

      // TODO: Add validation of mandatory fields
      createDocument({
        ...organisation,
      }).then(() => {
        store.state.UI.message =
            "Organisation created successfully, please wait for it to become accepted.";
        router.push({name: "organisations"});
      });
    };


    const updateOrganisation = (property, value, required) => {
      organisation[property] = value;

      if (required && !value) {
        inputError[property] = "This field is required";
      } else {
        inputError[property] = null;
      }

      if (property === 'category') {
        organisation.employeeCount = null;
        organisation.otherDescription = null;
        showOther.value = value === "Other";
        showEmployeeCount.value = value === "Company";
      }
      if (property === 'category') {
        showOther.value = value === "Other";
        showEmployeeCount.value = value === "Company";
      }
    };
    const onIndustryCategoryChange = (value) => {
      showSubCategory.value = true;
      subCategoriesAreDisabled.value = true;
      organisation.subCategory = "";
      organisation.industryCategory = value;
    };

    const onImageUrlChange = fileUrl => {
      organisation.imageUrl = fileUrl ?? '';
    };
    return {
      categories,
      updateOrganisation,
      countries,
      companySizes,
      form,
      hasRegistered,
      onImageUrlChange,
      onSubmit,
      organisation,
      organisationCategories,
      showEmployeeCount,
      showOther,
      showSubCategory,
      onIndustryCategoryChange,
      subCategories,
      subCategoriesAreDisabled,
      inputError,
      isValid,
      formError,

    };
  },
};
</script>
